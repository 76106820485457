"use client";
import { Toaster } from "@/components/ui/toaster";
import { useToast } from "@/components/ui/use-toast";
import { useEffect, useState } from "react";

type Props = {
  children: React.ReactNode;
};

function HotToastProv({ children }: Props) {
  const [toastId, setToastId] = useState("");
  const { toast, dismiss } = useToast();
  useEffect(() => {
    window.addEventListener("offline", (e) => {
      const { id } = toast({
        title: "غير متصل بالإنترنت - الرجاء التحقق من اتصالك بالإنترنت",
        // variant: "destructive",
        // color: "white",
        // description: (error as Error).message,
        duration: Infinity,
      });
      setToastId(id);
      console.log("offline");
    });

    window.addEventListener("online", (e) => {
      dismiss(toastId);
      console.log("online");
    });
  }, [toastId]);

  return (
    <div className="relative">
      {children}
      <Toaster />
    </div>
  );
}

export default HotToastProv;
