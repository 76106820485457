"use client";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { ReactElement } from "react";

type Props = {
  children?: ReactElement;
  newLang?: string;
  [key: string]: any;
};

function LangSwitcherClient({ children, newLang, ...props }: Props) {
  const pathname = usePathname();

  // Extract the pure path without language prefix
  const purePath = pathname.replace(/^\/[a-z]{2}/, "") || "/";

  // Determine the current language
  const currentLang = pathname.startsWith("/ar") ? "ar" : "en";

  // Determine the new language
  const targetLang = newLang || (currentLang === "en" ? "ar" : "en");

  // Construct the new path
  const newPath = `/${targetLang}${purePath}`;

  return (
    <Link
      href={newPath}
      {...props}
      prefetch={false}
    >
      {children}
    </Link>
  );
}

export default LangSwitcherClient;
