"use client";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import MyLink from "../myLink/MyLink";
import location from "@/public/images/location.svg";
import phone from "@/public/images/phone.svg";
import email from "@/public/images/email.svg";
import logo from "@/public/images/logo.svg";
import logoEn from "@/public/images/logo Safir Uni en.svg";

import faceboock from "@/public/images/Facebook.svg";
import telegram from "@/public/images/Telegram.svg";
import linkedin from "@/public/images/Linkedin.svg";
import twitter from "@/public/images/Twitter.svg";
import instegram from "@/public/images/Instagram.svg";
import rascoda from "@/public/images/rascoda.svg";
import { useTranslate } from "@/components/base/providers/TranslateProv";

const Footer = () => {
  const pathname = usePathname();

  const texts = {
    ar: {
      contactUs: "اتصل بنا في اي وقت",
      phoneNumbers: "01067035847",
      ourAddress: "العنوان الخاص بنا",
      addressDetails: "سيتم افتتاح الفرع قريبا",
      emailUs: "تواصل معنا عبر البريد",
      email: "contact@safiruni.com",
      followUs: "تابعنا",
      siteLinks: "راوبط الموقع",
      siteServices: "خدمات المنصة",
      companyInfo:
        "مرحبًا بكم في 'سفير الجامعات' - دليلك الشامل لعالم الجامعات الخاصة والأهلية وذات الطابع الخاص في مصر! 🎓",
      home: "الرئيسيه",
      universities: "الجامعات",
      colleges: "الكليات",
      // courses: "كورسات",
      aboutUs: "من نحن",
      articles: "مقالات",
      services: "خدماتنا",
      educationalConsultations: "استشارات تعليمية مخصصة",
      applicationSupport: "دعم عملية التقديم للجامعات",
      examPreparation: "إعداد للامتحانات الدولية والمحلية",
      scholarships: "خدمات المنح الدراسية والمساعدات المالية",
      communicationPlatforms: "منصات التواصل والتوجيه",
      rightsReserved: ``,
      privacyPolicy: "سياسة الخصوصية",
      termsAndConditions: "الشروط والأحكام",
      contactUsLink: "تواصل معنا",
    },
    en: {
      contactUs: "Contact us anytime",
      phoneNumbers: "01067035847",
      ourAddress: "Our Address",
      addressDetails: "The branch will be opened soon",
      emailUs: "Reach us via email",
      email: "contact@safiruni.com",
      followUs: "Follow us",
      siteLinks: "Site Links",
      siteServices: "Platform Services",
      companyInfo:
        "Welcome to 'Safir Universities' - the comprehensive guide to the private and public universities of Egypt! 🎓",
      home: "Home",
      universities: "Universities",
      colleges: "Colleges",
      courses: "Courses",
      aboutUs: "About Us",
      articles: "Articles",
      services: "Our Services",
      educationalConsultations: "Custom Educational Consultations",
      applicationSupport: "University Application Support",
      examPreparation: "International and Local Exam Preparation",
      scholarships: "Scholarship and Financial Aid Services",
      communicationPlatforms: "Communication and Guidance Platforms",
      rightsReserved:
        "© All rights reserved, Rascoda | Made with love 💜 by Rascoda.com",
      privacyPolicy: "Privacy Policy",
      termsAndConditions: "Terms and Conditions",
      contactUsLink: "Contact Us",
    },
  };

  const { locale }: { locale: "ar" | "en" } = useTranslate();

  const t = texts[locale];
  if (pathname?.includes("dashboard")) return null;

  return (
    <footer className="bg-white pb-10 border-t">
      <div>
        <div className="flex flex-col mx-2 lg:flex-row lg:items-center justify-center gap-5 flex-wrap mb-10 pb-5 border-b border-[#EBEBEB]">
          <div className="flex items-center gap-3 py-5">
            <Image
              src={phone}
              alt="location"
            />
            <div>
              <p className="text-darkText font-[400]">{t.contactUs}</p>
              <p className="text-[#6C7278] font-[400]">{t.phoneNumbers}</p>
            </div>
          </div>
          <div className="flex items-center gap-3 py-5">
            <Image
              src={location}
              alt="location"
            />
            <div>
              <p className="text-darkText font-[400]">{t.ourAddress}</p>
              <p className="text-[#6C7278] font-[400]">{t.addressDetails}</p>
            </div>
          </div>
          <div className="flex items-center gap-3 py-5">
            <Image
              src={email}
              alt="email"
            />
            <div>
              <p className="text-darkText font-[400]">{t.emailUs}</p>
              <p className="text-[#6C7278] font-[400]">{t.email}</p>
            </div>
          </div>
        </div>

        <div className="bg-white border border-[#EBEBEB] rounded-[30px] py-12 mx-2 lg:mx-16">
          <div className="flex flex-col lg:flex-row justify-center gap-[4rem] lg:gap-[8rem] xl:gap-[10rem] border-b border-[#EBEBEB] w-fit mx-auto pb-16 px-10">
            <div className="flex flex-col gap-5">
              {locale == "ar" ? (
                <Image
                  src={logo}
                  alt="logo"
                />
              ) : (
                <Image
                  src={logoEn}
                  alt="logo"
                />
              )}
              <p className="text-descriptionText text-16 max-w-[480px] xl:max-w-[540px]">
                {t.companyInfo}
              </p>
              <p className="text-24 text-descriptionText font-[500]">
                {t.followUs}
              </p>
              <div className="flex items-center gap-3 flex-wrap">
                <a href="https://www.facebook.com/SafirUniversities/">
                  <Image
                    src={faceboock}
                    alt="faceboock"
                  />
                </a>
                <Image
                  src={telegram}
                  alt="telegram"
                />
                <Image
                  src={linkedin}
                  alt="linkedin"
                />
                <a href="https://www.tiktok.com/@safir_uni">
                  <Image
                    src={twitter}
                    alt="twitter"
                  />
                </a>
                <a href="https://www.instagram.com/safiruniversities/">
                  <Image
                    src={instegram}
                    alt="instegram"
                  />
                </a>
              </div>
            </div>
            <div>
              <p className="text-25 text-darkText font-[500] mb-5 text-nowrap">
                {t.siteLinks}
              </p>
              <ul className="flex flex-col gap-2">
                <li>
                  <Link
                    href={"/"}
                    className="text-16 text-descriptionText font-[500]"
                  >
                    {t.home}
                  </Link>
                </li>
                <li>
                  <Link
                    href={"/universities"}
                    className="text-16 text-descriptionText font-[500]"
                  >
                    {t.universities}
                  </Link>
                </li>
                <li>
                  <Link
                    href={"#"}
                    className="text-16 text-descriptionText font-[500]"
                  >
                    {t.colleges}
                  </Link>
                </li>
                {/* <li> */}
                {/* <Link
                    href={"/courses"}
                    className="text-16 text-descriptionText font-[500]"
                  >
                    {t.courses}
                  </Link> */}
                {/* </li> */}
                <li>
                  <Link
                    href={"/aboutUs"}
                    className="text-16 text-descriptionText font-[500]"
                  >
                    {t.aboutUs}
                  </Link>
                </li>
                <li>
                  <Link
                    href={"/articles"}
                    className="text-16 text-descriptionText font-[500]"
                  >
                    {t.articles}
                  </Link>
                </li>
                <li>
                  <Link
                    href={"/services"}
                    className="text-16 text-descriptionText font-[500]"
                  >
                    {t.services}
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <p className="text-25 text-darkText font-[500] mb-5">
                {t.siteServices}
              </p>
              <ul className="flex flex-col gap-2">
                <li>
                  <Link
                    href={"#"}
                    className="text-16 text-descriptionText font-[500]"
                  >
                    {t.educationalConsultations}
                  </Link>
                </li>
                <li>
                  <Link
                    href={"#"}
                    className="text-16 text-descriptionText font-[500]"
                  >
                    {t.applicationSupport}
                  </Link>
                </li>
                <li>
                  <Link
                    href={"#"}
                    className="text-16 text-descriptionText font-[500]"
                  >
                    {t.examPreparation}
                  </Link>
                </li>
                <li>
                  <Link
                    href={"#"}
                    className="text-16 text-descriptionText font-[500]"
                  >
                    {t.scholarships}
                  </Link>
                </li>
                <li>
                  <Link
                    href={"#"}
                    className="text-16 text-descriptionText font-[500]"
                  >
                    {t.communicationPlatforms}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col mx-2 gap-3 items-center justify-center mt-10">
            <p className="text-16 text-descriptionText text-center font-[500] text-wrap">
              {/* {t.rightsReserved} */}©{" "}
              {locale == "ar" ? "جميع الحقوق محفوظة،" : "All rights reserved"}{" "}
              <a
                href="http://rascoda.com"
                className="font-bold"
                target="_blank"
              >
                Rascoda
              </a>{" "}
              {locale == "ar"
                ? "| صنع بكل حب 💜 تنفيذ و تطوير"
                : "| Made with love 💜 by"}
              <a
                href="http://rascoda.com"
                className="font-bold"
                target="_blank"
              >
                Rascoda.com
              </a>{" "}
            </p>
            <ul className="flex gap-3">
              <li>
                <MyLink
                  href={"/privacy-policy"}
                  className="text-14 text-descriptionText"
                >
                  {t.privacyPolicy}
                </MyLink>
              </li>
              <li>
                <MyLink
                  href={"/terms-and-conditions"}
                  className="text-14 text-descriptionText"
                >
                  {t.termsAndConditions}
                </MyLink>
              </li>
              <li>
                <MyLink
                  href={"/contactUs"}
                  className="text-14 text-descriptionText"
                >
                  {t.contactUsLink}
                </MyLink>
              </li>
            </ul>
            <a
              href="http://rascoda.com"
              target="_blank"
            >
              <Image
                src={rascoda}
                alt="rascoda"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
