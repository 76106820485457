"use client";
import Image from "next/image";
import logo from "@/public/images/logo.svg";
import logoEn from "@/public/images/logo Safir Uni en.svg";
import AppBar from "./AppBar";
import { MobileAppBar } from "./MobileAppBar";
import { usePathname } from "next/navigation";
import MyLink from "../myLink/MyLink";
import { useTranslate } from "@/components/base/providers/TranslateProv";

import LanguageSwitcher from "./LanguageSwitcher";
import LoginAndUser from "./Login&User";

const Header = () => {
  const pathname = usePathname();
  const { locale } = useTranslate();

  if (pathname?.includes("dashboard")) return <></>;

  return (
    <div className="border-b border-[#E0E0E0] sticky top-0 p-3 md:py-4 bg-white z-[49]">
      <div className="container flex items-center justify-between gap-10 ">
        <MyLink href={"/"}>
          {locale === "ar" ? (
            <Image
              src={logo}
              alt="logo"
              className="w-[180px] min-w-[180px] h-full"
            />
          ) : (
            <Image
              src={logoEn}
              alt="logo"
              className="w-[180px] min-w-[120px] h-full"
            />
          )}
        </MyLink>
        <div className="grow hidden xl:flex items-center gap-5">
          <AppBar />

          <LanguageSwitcher />
          {/* Login button */}
          <LoginAndUser />
        </div>
        <MobileAppBar />
      </div>
    </div>
  );
};

export default Header;
