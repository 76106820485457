"use client"; // Ensure this line is included

import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import { X } from "lucide-react";
import { useGetAdsPopupQuery } from "@/redux/features/api/home/homeApiSlice";
import { useTranslate } from "../base/providers/TranslateProv";
import { usePathname } from "next/navigation";

const PopupAd: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [nextShowTime, setNextShowTime] = useState<number | null>(null);
  const { data, refetch } = useGetAdsPopupQuery({});
  const pathname = usePathname();
  const { locale }: { locale: "ar" | "en" } = useTranslate();
  const adTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Get the current time in milliseconds
  const now = Date.now();

  useEffect(() => {
    // Calculate the time when the ad should next appear
    const calculateNextShowTime = () => {
      if (isClosed && nextShowTime) {
        const remainingTime = Math.max(0, nextShowTime - now);
        return remainingTime;
      }
      if (isVisible) {
        return 0; // Ad is currently visible
      }
      return 40000; // 40 seconds for initial ad display
    };

    const remainingTime = calculateNextShowTime();

    if (remainingTime > 0) {
      adTimeoutRef.current = setTimeout(() => {
        setIsVisible(true);
        if (isClosed) {
          setIsClosed(false);
          setNextShowTime(now + 120000); // 120 seconds from now
        }
      }, remainingTime);
    }

    return () => {
      if (adTimeoutRef.current) {
        clearTimeout(adTimeoutRef.current);
      }
    };
  }, [isVisible, isClosed, nextShowTime, pathname, now, refetch]);

  const handleClose = () => {
    setIsVisible(false);
    setIsClosed(true);
    setNextShowTime(Date.now() + 120000); // Set the time for the next ad appearance
  };

  const handleImageClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  // Return null for routes where the ad should not appear
  if (
    pathname.includes("/ar/dashboard") ||
    pathname.includes("/en/dashboard")
  ) {
    return null;
  }

  return (
    <>
      {data?.data && data?.data?.image && isVisible && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-[1500]"
          style={{ pointerEvents: isVisible ? "auto" : "none" }} // Ensure clicks go through when visible
        >
          <div className="relative w-[90%] min-w-[300px] min-h-[300px] lg:max-w-[500px] h-auto rounded-lg shadow-lg mx-auto z-[1600]">
            <a
              href={data?.data?.link ? data?.data?.link[locale] : ""}
              target="_blank"
            >
              <Image
                src={data?.data?.image ? data?.data?.image[locale] : ""}
                alt="PopupAd"
                width={500}
                height={300}
                className="rounded-[18px] "
                onClick={handleImageClick}
              />
            </a>
            <div
              onClick={handleClose}
              className="bg-[#ebebeb] rounded-[8px] cursor-pointer absolute top-[10px] right-[10px] z-[1700]" // Increase z-index here too
            >
              <div className="w-[30px] h-[30px] flex items-center justify-center">
                <X size={20} className="text-black" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupAd;
