import { lang } from "./langType";

function getLinkWithLang(href: string = "", lang: lang = "ar") {
  const linkWithLang =
    href === `/${lang}` || href === `/` ? `/${lang}` : `/${lang}${href}`;
  const link = lang ? linkWithLang : href;
  return link;
}

export default getLinkWithLang;
