"use client";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import MyLink from "../myLink/MyLink";
import { usePathname } from "next/navigation";

export default function AppBar({
  isInMobile = false,
  closeSideMenu,
}: {
  isInMobile?: boolean;
  closeSideMenu?: () => void;
}) {
  const appBarLinks = [
    {
      id: "link-1",
      href: "/",
      label: {
        ar: "الرئيسية",
        en: "Home",
      },
    },
    {
      id: "link-2",
      href: "/universities",
      label: {
        ar: "الجامعات",
        en: "Universities",
      },
    },
    {
      id: "link-3",
      href: "/faculities",
      label: {
        ar: "الكليات",
        en: "Faculties",
      },
    },
    {
      id: "link-4",
      href: "/specialties",
      label: {
        ar: "التخصصات",
        en: "Programs",
      },
    },
    // Uncomment if needed
    // {
    //   id: "link-5",
    //   href: "/courses",
    //   label: {
    //     ar: "كورسات",
    //     en: "Courses"
    //   }
    // },
    {
      id: "link-6",
      href: "/aboutUs",
      label: {
        ar: "من نحن",
        en: "About Us",
      },
    },
    {
      id: "link-7",
      href: "/articles",
      label: {
        ar: "مقالات",
        en: "Articles",
      },
    },
    {
      id: "link-8",
      href: "/services",
      label: {
        ar: "خدماتنا",
        en: "Our Services",
      },
    },
  ];

  const { locale } = useTranslate();
  const pathname = usePathname();

  // Helper function to check if the link is active
  const isActiveLink = (href: string): boolean => {
    // Remove locale prefix for comparison if present
    const pathWithoutLocale = pathname.replace(`/${locale}`, "") || "/";
    // Compare base path only
    return href === pathWithoutLocale;
  };

  return (
    <ul className="flex flex-col min-w-[200px] p-3 xl:p-0 xl:w-full xl:flex-row gap-3 xl:gap-5 grow">
      {appBarLinks.map((link) => (
        <li key={link.id}>
          <MyLink
            href={link.href || "#"}
            className={`text-xl xl:text-16  font-[500] text-nowrap ${
              isActiveLink(link.href)
                ? "font-bold text-primaryColor"
                : "text-darkText"
            }`}
            onClick={() => {
              if (closeSideMenu && isInMobile) {
                closeSideMenu();
              }
            }}
          >
            {locale === "ar" ? link.label.ar : link.label.en}
          </MyLink>
        </li>
      ))}
    </ul>
  );
}
