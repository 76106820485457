"use client";

import * as React from "react";
import { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  User,
  Heart,
  ShoppingBag,
  LogOut,
  CircleCheckBig,
  ClipboardList,
} from "lucide-react";
import MyLink from "../myLink/MyLink";
import { useTranslate } from "@/components/base/providers/TranslateProv";

export function DropdownMenuUser({ open, logout }: any) {
  const { locale } = useTranslate();
  const isRTL = locale === "ar";

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="w-full">{open}</div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={`w-56 ${isRTL ? "text-right" : "text-left"}`}
      >
        <div className="w-full">
          <ul className="bg-white rounded shadow-xl">
            <li>
              <MyLink
                className={`flex items-center ${
                  isRTL ? "justify-end" : " flex-row-reverse justify-end"
                } gap-[10px] p-3 cursor-pointer hover:text-primaryColor hover:bg-[#E8F0FE] rounded mb-2 `}
                href={`/profile?index=1`}
              >
                <span>{isRTL ? "البيانات الشخصية" : "Personal Data"}</span>
                <User size={24} />
              </MyLink>
            </li>
            <li>
              <MyLink
                className={`flex items-center ${
                  isRTL ? "justify-end" : "flex-row-reverse justify-end"
                } gap-[10px] p-3 cursor-pointer hover:text-primaryColor hover:bg-[#E8F0FE] rounded mb-2`}
                href={`/profile?index=2`}
              >
                <span>{isRTL ? "استشاراتي" : "My Consultations"}</span>
                <CircleCheckBig size={20} className="ml-2" />
              </MyLink>
            </li>
            <li>
              <MyLink
                className={`flex items-center ${
                  isRTL ? "justify-end" : "flex-row-reverse justify-end"
                } gap-[10px] p-3 cursor-pointer hover:text-primaryColor hover:bg-[#E8F0FE] rounded mb-2`}
                href={`/profile?index=3`}
              >
                <span>{isRTL ? "المفضلة" : "Favorites"}</span>
                <Heart size={20} className="ml-2" />
              </MyLink>
            </li>
            <li>
              <MyLink
                className={`flex items-center ${
                  isRTL ? "justify-end" : "flex-row-reverse justify-end"
                } gap-[10px] p-3 cursor-pointer hover:text-primaryColor hover:bg-[#E8F0FE] rounded mb-2`}
                href={`/profile?index=4`}
              >
                <span>{isRTL ? "طلباتي" : "My Orders"}</span>
                <ShoppingBag size={20} className="ml-2" />
              </MyLink>
            </li>

            <li
              onClick={logout}
              className="flex items-center justify-center p-3 cursor-pointer hover:bg-red-100 text-red-500 mt-2"
            >
              <span>{isRTL ? "تسجيل الخروج" : "Log Out"}</span>
              <LogOut size={20} className="ml-2" />
            </li>
          </ul>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
