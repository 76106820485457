"use client";
import Loader from "@/components/shared/Loader";
import React, { useEffect, useState } from "react";

type Props = {
  children: React.ReactNode;
};

export default function CopyLayout({ children }: Props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const path = window?.location?.pathname;

    // Disable protection on certain paths
    const disableProtection =
      path.startsWith("/ar/dashboard") || path.startsWith("/en/dashboard");

    if (disableProtection) {
      setLoading(false);
      return;
    }

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Function to disable copy-paste functionality
    const disableCopyPaste = (e: ClipboardEvent) => {
      e.preventDefault();
    };
    document.addEventListener("copy", disableCopyPaste);
    document.addEventListener("cut", disableCopyPaste);

    // Function to disable right-click functionality
    const disableRightClick = (e: MouseEvent) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", disableRightClick);

    // Function to disable inspect element (F12, Ctrl+Shift+I)
    const disableInspect = (e: KeyboardEvent) => {
      if (
        e.key === "F12" ||
        (e.ctrlKey && e.shiftKey && e.key === "I") ||
        (e.ctrlKey && e.shiftKey && e.key === "C") ||
        (e.ctrlKey && e.shiftKey && e.key === "J")
      ) {
        e.preventDefault();
      }
    };
    document.addEventListener("keydown", disableInspect);

    // Function to disable PrintScreen key for basic screenshot prevention
    const disablePrintScreen = (e: KeyboardEvent) => {
      if (e.key === "PrintScreen") {
        e.preventDefault();
        navigator.clipboard.writeText(""); // Clears the clipboard content
        alert("Screenshots are disabled on this site.");
      }
    };
    document.addEventListener("keydown", disablePrintScreen);

    // Function to blur the screen on visibility change (e.g., switching tabs)
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        document.body.style.filter = "blur(10px)";
      } else {
        document.body.style.filter = "none";
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up event listeners when the component unmounts
    return () => {
      clearTimeout(timer);
      document.removeEventListener("copy", disableCopyPaste);
      document.removeEventListener("cut", disableCopyPaste);
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableInspect);
      document.removeEventListener("keydown", disablePrintScreen);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  if (loading) {
    return (
      <div>
        <Loader fullHeight />
      </div>
    );
  }

  return <>{children}</>;
}
