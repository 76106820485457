import { apiProfileSlice } from "@/redux/app/api/apiSlice";

export const profileApiSlice = apiProfileSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSpecificProfile: builder.query({
      query: () => ({
        url: `/api/profile/show`,
      }),
      providesTags: ["Profile"],
    }),

    updateProfile: builder.mutation({
      query: (body) => ({
        url: `/api/profile/update`,
        method: "POST",
        body: body,
      }),
      // providesTags: ["Profile"],
      invalidatesTags: ["Profile"],
    }),
    getUserConsultations: builder.query({
      query: () => ({
        url: `/api/consultation`,
      }),
      providesTags: ["Profile"],
    }),
    getUserConsultationsFree: builder.query({
      query: () => ({
        url: `/api/consultation/orders`,
      }),
      providesTags: ["Profile"],
    }),
  }),
});

export const {
  useUpdateProfileMutation,
  useGetSpecificProfileQuery,
  useGetUserConsultationsQuery,
  useGetUserConsultationsFreeQuery,
} = profileApiSlice;
