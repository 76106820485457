"use client";
import { getTranslate } from "@/utils/lang/getTranslate";
import { lang } from "@/utils/lang/langType";
import React from "react";

type Dictionary = Awaited<ReturnType<typeof getTranslate>>;

const DictionaryContext = React.createContext<Dictionary | null>(null);

export default function TranslateProv({
  dictionary,
  lang,
  children,
}: {
  dictionary: Dictionary;
  lang: lang;
  children: React.ReactNode;
}) {
  const translate = { t: dictionary, locale: lang };
  return (
    <DictionaryContext.Provider value={translate}>
      {children}
    </DictionaryContext.Provider>
  );
}

export function useTranslate(fileName?: string) {
  const translate = React.useContext(DictionaryContext);
  if (translate === null) {
    throw new Error("useTranslate hook must be used within TranslateProv");
  }
  return {
    t: fileName ? translate.t[fileName] : translate.t,
    locale: translate.locale,
  };
}

// 'use client'
// export default function YourClientComponent(){
//    const {t, locale} = useDictionary()
//    return <div>{dictionary.something}</div>
// }
