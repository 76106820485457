import { apiHomeSlice } from "@/redux/app/api/apiSlice";

export const homeApiSlice = apiHomeSlice.injectEndpoints({
  endpoints: (builder) => ({
    // anonymous
    getAllUniversities: builder.query({
      query: (params) => ({
        url: `api/home/recommended/universities`,
        params: { ...params },
      }),
      providesTags: ["Home"],
    }),
    getSelectUniversity: builder.query({
      query: (params) => ({
        url: `/api/home/select/university`,
        params: { ...params },
      }),
      providesTags: ["Home"],
    }),
    getAllFaculties: builder.query({
      query: (params) => ({
        url: `/api/home/recommended/general-faculties`,
        params: { ...params },
      }),
      providesTags: ["Home"],
    }),
    getRecommendedAds: builder.query({
      query: (params) => ({
        url: `/api/home/tarwij`,
        params: { ...params },
      }),
      providesTags: ["Home"],
    }),
    getCourses: builder.query({
      query: (params) => ({
        url: `/api/home/recommended/courses`,
        params: { ...params },
      }),
      providesTags: ["Home"],
    }),
    getArticles: builder.query({
      query: (params) => ({
        url: `/api/home/recommended/blogs`,
        params: { ...params },
      }),
      providesTags: ["Home"],
    }),
    getSingleUniversities: builder.query({
      query: (params) => ({
        url: `api/home/get/university`,
        params: { ...params },
      }),
      providesTags: ["Home"],
    }),
    getSingleUniversitiesTest: builder.query({
      query: (id) => ({
        url: `api/home/get/university/?university_id=${id}`,
        // params: { ...params },
      }),
      // providesTags: ["Home"],
    }),
    getAdsPopup: builder.query({
      query: (params) => ({
        url: `/api/home/tarwij/popup`,
      }),
    }),
  }),
});

export const {
  useGetAllUniversitiesQuery,
  useLazyGetAllUniversitiesQuery,
  useGetSelectUniversityQuery,
  useGetAllFacultiesQuery,
  useGetRecommendedAdsQuery,
  useGetCoursesQuery,
  useGetArticlesQuery,
  useGetSingleUniversitiesQuery,
  useGetSingleUniversitiesTestQuery,
  useGetAdsPopupQuery,
} = homeApiSlice;
