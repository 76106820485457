"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCookie, setCookie, deleteCookie } from "cookies-next";

import { AuthPayload, AuthState } from "@/redux/types/auth/AuthTypes";
import { daysToMs } from "@/utils/date/dayToMs";

const initialState: AuthState = {
  email: getCookie("email") || "",
  token: getCookie("token") || null,
  first_name: getCookie("first_name") || null,
  last_name: getCookie("last_name") || null,
  is_admin: getCookie("is_admin") || null,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<AuthPayload>) => {
      console.log("action.payload.data", action.payload);
      const { email, token, first_name, last_name, is_admin, image } =
        action.payload;
      state.email = email;
      state.first_name = first_name;
      state.last_name = last_name;
      state.token = token;
      email && setCookie("email", email, { maxAge: daysToMs(1000) });
      token && setCookie("token", token, { maxAge: daysToMs(1000) });
      first_name &&
        setCookie("first_name", first_name, { maxAge: daysToMs(1000) });
      last_name &&
        setCookie("last_name", last_name, { maxAge: daysToMs(1000) });
      is_admin && setCookie("roles", is_admin, { maxAge: daysToMs(1000) });
      image && setCookie("image", image, { maxAge: daysToMs(1000) });
      token && deleteCookie("register");
      token && deleteCookie("forget");
    },
    updateUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      action.payload &&
        setCookie("user", action.payload, { maxAge: daysToMs(1000) });
    },
    updateToken: (state, action: PayloadAction<any>) => {
      state.token = action.payload;
      action.payload &&
        setCookie("token", action.payload, { maxAge: daysToMs(1000) });
      deleteCookie("register");
      deleteCookie("forget");
    },
    logout: (state) => {
      state.email = null;
      state.user = null;
      state.token = null;
      deleteCookie("email");
      deleteCookie("token");
      deleteCookie("user");
      deleteCookie("image");
      deleteCookie("first_name");
      deleteCookie("last_name");
      deleteCookie("roles");

      //cookies for protect paths
      deleteCookie("role");
      deleteCookie("register");
      deleteCookie("forget");
      window.location.href = "/";
    },
  },
});

const authReducer = authSlice.reducer;

export const { setCredentials, logout, updateUser, updateToken } =
  authSlice.actions;

export default authReducer;

// import { setCredentials } from "@/redux/features/local/auth/authSlice";

// const userData = await login(values).unwrap();
// dispatch(setCredentials({ ...userData?.data, email: values?.email }));
// router.push("/");

// dispatch(logout());
