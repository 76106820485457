import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/safiruni/front/app/[lang]/copyLauout.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/app/[lang]/custom.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/safiruni/front/components/base/providers/HotToastProv.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/safiruni/front/components/base/providers/ReduxProv.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/safiruni/front/components/base/providers/TranslateProv.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/safiruni/front/components/pop up ad/PopupAd.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/safiruni/front/components/profile/MobileNavbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/safiruni/front/components/SetCookies/SetCookies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/safiruni/front/components/shared/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/safiruni/front/components/shared/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/node_modules/next/font/google/target.css?{\"path\":\"app/[lang]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/node_modules/next/font/local/target.css?{\"path\":\"app/[lang]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/SomarSans-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/SomarSans-Regular.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/SomarSans-Bold.ttf\",\"weight\":\"700\",\"style\":\"bold\"}],\"variable\":\"--font-somar-sans\"}],\"variableName\":\"somarSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer","Bounce"] */ "/var/www/front/safiruni/front/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/node_modules/react-toastify/dist/ReactToastify.css");
