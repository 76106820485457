"use client";

import { getCookie } from "cookies-next";
import { User, UserPlus } from "lucide-react";
import MyLink from "../myLink/MyLink";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import { DropdownMenuUser } from "./DropdownMenuUser";
import { useAppDispatch } from "@/redux/app/hooks";
import { logout } from "@/redux/features/local/auth/authSlice";
import UserProfileCard from "./UserProfileCard ";
type Props = {};

export default function LoginAndUser({}: Props) {
  // Get user name from cookies
  const token = getCookie("token");

  const { locale }: { locale: "ar" | "en" } = useTranslate();
  const dispatch = useAppDispatch();

  const handleLogOut = () => {
    dispatch(logout());
  };
  return (
    <div>
      {token ? (
        <DropdownMenuUser logout={handleLogOut} open={<UserProfileCard />} />
      ) : (
        <div className=" flex flex-col lg:flex-row items-center justify-center gap-3">
          <MyLink
            href={`/signup`}
            className="flex h-12 w-[120px]  bg-primaryColor text-sm text-white font-[500] items-center justify-center gap-[6px] rounded-[5px]"
          >
            <User width={20} height={20} />
            {locale === "ar" ? "انشاء حساب" : "Signup"}
          </MyLink>
          <MyLink
            href={`/login`}
            className="flex h-12 w-[120px] gap-[6px]  text-primaryColor text-sm border border-primaryColor font-[500] items-center justify-center rounded-[5px]"
          >
            <UserPlus width={20} height={20} />
            {/* تسجيل دخول */}
            {locale === "ar" ? "تسجيل دخول" : "Login"}
          </MyLink>
        </div>
      )}
    </div>
  );
}
