"use client";
import { useState } from "react";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Menu } from "lucide-react";
import AppBar from "./AppBar";
import LanguageSwitcher from "./LanguageSwitcher";
import LoginAndUser from "./Login&User";
import { useTranslate } from "@/components/base/providers/TranslateProv";

export function MobileAppBar() {
  const { locale } = useTranslate();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <Sheet
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <SheetTrigger asChild>
        <Menu
          className="text-gray-400 hover:text-green-700 transition cursor-pointer block xl:hidden"
          onClick={handleOpen}
        />
      </SheetTrigger>
      <SheetContent
        className="w-auto px-1 !xl:hidden space-y-3"
        side={locale === "en" ? "left" : "right"}
      >
        <AppBar
          isInMobile={true}
          closeSideMenu={handleClose}
        />
        <LoginAndUser />
        <LanguageSwitcher />
      </SheetContent>
    </Sheet>
  );
}
