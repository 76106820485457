// components/MyLink.tsx
import getLinkWithLang from "@/utils/lang/getLinkWithLang";
import { lang } from "@/utils/lang/langType";
import { getCookie } from "cookies-next";
import NextLink, { LinkProps } from "next/link";
import { AnchorHTMLAttributes, FC, ReactNode } from "react";

interface MyLinkProps
  extends LinkProps,
    AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  href: string;
  lang?: lang;
}

const MyLink: FC<MyLinkProps> = ({ href, children, lang, ...props }) => {
  const langLocale = (getCookie("lang") as lang) || lang || "en";
  return (
    <NextLink
      href={getLinkWithLang(href, langLocale)}
      prefetch={false}
      {...props}
    >
      {children}
    </NextLink>
  );
};

export default MyLink;
