import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { setCredentials, logout } from "@/redux/features/local/auth/authSlice";
import { RootState } from "../store";
import baseUrl from "@/api/baseUrl";

export const baseQueryWithReAuth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: any
) => {
  const fetchArgs = typeof args === "function" ? args(api) : args;

  const fetchBase = fetchBaseQuery({
    baseUrl,
    credentials: "omit", // include || omit || same-origin
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    },
  });

  try {
    const result = await fetchBase(fetchArgs, api, extraOptions);
    if (result.error && (result.error as FetchBaseQueryError)?.status === 401) {
      api.dispatch(logout());
    }
    return result;
  } catch (error) {
    throw error;
  }
};

// Create API slices
export const apiAuthSlice = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({}),
  tagTypes: ["Auth"],
});

export const apiNoTokenAuthSlice = createApi({
  reducerPath: "authNoTokenApi",
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({}),
  tagTypes: ["AuthNoToken"],
});

export const apiHomeSlice = createApi({
  reducerPath: "homeApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Home"],
});

export const apiConsultationSlice = createApi({
  reducerPath: "consultationApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Consultation"],
});

export const apiWebsiteSlice = createApi({
  reducerPath: "websiteApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Website"],
});

export const apiUniversitySlice = createApi({
  reducerPath: "universityApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["University"],
});

export const apiFacultySlice = createApi({
  reducerPath: "facultyApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Faculty"],
});

export const apiFaculitiesListSlice = createApi({
  reducerPath: "faculitiesListApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["FaculitiesList"],
});

export const apiSpecializationSlice = createApi({
  reducerPath: "specializationApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Specialization"],
});

export const apiSpecializationListSlice = createApi({
  reducerPath: "specializationListApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["SpecializationList"],
});

export const apiArticleSlice = createApi({
  reducerPath: "articleApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Article"],
});

export const apiAdsSlice = createApi({
  reducerPath: "adsApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Ads"],
});

export const apiCurrencySlice = createApi({
  reducerPath: "currencyApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Currency"],
});

export const apiPagesSlice = createApi({
  reducerPath: "pagesApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Pages"],
});

export const apiProfileSlice = createApi({
  reducerPath: "profileApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Profile"],
});

export const apiTeachersSlice = createApi({
  reducerPath: "teachersApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Teachers"],
});

export const apiCoursesSlice = createApi({
  reducerPath: "coursesApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Courses"],
});

export const apiConsultationsSlice = createApi({
  reducerPath: "consultationsApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Consultations"],
});

export const apiServicesSlice = createApi({
  reducerPath: "servicesApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Services"],
});

export const apiBlogSlice = createApi({
  reducerPath: "blogApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Blogs"],
});

export const apiContactSlice = createApi({
  reducerPath: "contactApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Contact"],
});

export const apiDropDown = createApi({
  reducerPath: "dropdownApi",
  baseQuery: baseQueryWithReAuth,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["DropDown"],
});
