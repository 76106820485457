"use client";
import Image from "next/image";
import { useTranslate } from "../base/providers/TranslateProv";
import MyLink from "../shared/myLink/MyLink";
import { usePathname } from "next/navigation";
import faculities1 from "@/public/images/profile/Case-1.svg";
import faculities from "@/public/images/profile/Case.svg";
import specialties1 from "@/public/images/profile/Diploma-1.svg";
import specialties from "@/public/images/profile/Diploma.svg";
import Home1 from "@/public/images/profile/Home-1.svg";
import Home from "@/public/images/profile/Home.svg";
import profile1 from "@/public/images/profile/UserRounded-1.svg";
import profile from "@/public/images/profile/UserRounded.svg";
import universities1 from "@/public/images/profile/SquareAcademicCap-1.svg";
import universities from "@/public/images/profile/SquareAcademicCap.svg";

type Props = {};

export default function MobileNavbar({}: Props) {
  const appBarLinks = [
    {
      id: "link-1",
      href: "/",
      label: {
        ar: "الرئيسية",
        en: "Home",
      },
      icon: Home,
      activeIcon: Home1,
    },
    {
      id: "link-2",
      href: "/universities",
      label: {
        ar: "الجامعات",
        en: "Universities",
      },
      icon: universities,
      activeIcon: universities1,
    },
    {
      id: "link-3",
      href: "/faculities",
      label: {
        ar: "الكليات",
        en: "Faculties",
      },
      icon: faculities,
      activeIcon: faculities1,
    },
    {
      id: "link-4",
      href: "/specialties",
      label: {
        ar: "التخصصات",
        en: "Programs",
      },
      icon: specialties,
      activeIcon: specialties1,
    },

    {
      id: "link-6",
      href: "/profile",
      label: {
        ar: "الملف الشخصي ",
        en: "Profile",
      },
      icon: profile,
      activeIcon: profile1,
    },
  ];
  const { locale } = useTranslate();
  const pathname = usePathname();

  const isActiveLink = (href: string): boolean => {
    // Remove locale prefix for comparison if present
    const pathWithoutLocale = pathname.replace(`/${locale}`, "") || "/";
    // Compare base path only
    return href === pathWithoutLocale;
  };
  return (
    <div className="sm:hidden h-[85px]  pb-3 fixed bottom-0 left-0 w-full  bg-white z-[100] border-t">
      <ul className="flex w-full h-full  items-center justify-between  ">
        {appBarLinks.map((link: any) => (
          <li key={link.id} className="w-full h-full ">
            <MyLink
              href={link.href || "#"}
              className={` max-w-[78px] text-[12px] h-[65px]  mx font-[400] text-center text-nowrap flex items-center justify-center flex-col gap-2 ${
                isActiveLink(link.href)
                  ? "font-bold text-[#465FF1] bg-[#4660f11a] "
                  : "text-darkText"
              }`}
            >
              <Image
                src={isActiveLink(link.href) ? link.activeIcon : link.icon}
                alt="home"
                className="w-6 h-6 mt-2"
              />
              {locale === "ar" ? link.label.ar : link.label.en}
            </MyLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
