import { configureStore } from "@reduxjs/toolkit";
import {
  apiAuthSlice,
  apiNoTokenAuthSlice,
  apiHomeSlice,
  apiWebsiteSlice,
  apiUniversitySlice,
  apiFacultySlice,
  apiFaculitiesListSlice,
  apiSpecializationSlice,
  apiArticleSlice,
  apiAdsSlice,
  apiCurrencySlice,
  apiPagesSlice,
  apiProfileSlice,
  apiTeachersSlice,
  apiCoursesSlice,
  apiServicesSlice,
  apiDropDown,
  apiSpecializationListSlice,
  apiBlogSlice,
  apiConsultationsSlice,
  apiContactSlice,
  apiConsultationSlice,
} from "./api/apiSlice";
import authReducer from "../features/local/auth/authSlice";
// import checkListReducer from "../features/local/example/checkListSlice";

const store = configureStore({
  reducer: {
    // api
    [apiAuthSlice.reducerPath]: apiAuthSlice.reducer,
    [apiNoTokenAuthSlice.reducerPath]: apiNoTokenAuthSlice.reducer,
    [apiConsultationSlice.reducerPath]: apiConsultationSlice.reducer,
    [apiHomeSlice.reducerPath]: apiHomeSlice.reducer,
    [apiWebsiteSlice.reducerPath]: apiWebsiteSlice.reducer,
    [apiUniversitySlice.reducerPath]: apiUniversitySlice.reducer,
    [apiFacultySlice.reducerPath]: apiFacultySlice.reducer,
    [apiFaculitiesListSlice.reducerPath]: apiFaculitiesListSlice.reducer,
    [apiSpecializationSlice.reducerPath]: apiSpecializationSlice.reducer,
    [apiSpecializationListSlice.reducerPath]:
      apiSpecializationListSlice.reducer,
    [apiBlogSlice.reducerPath]: apiBlogSlice.reducer,
    [apiContactSlice.reducerPath]: apiContactSlice.reducer,
    [apiArticleSlice.reducerPath]: apiArticleSlice.reducer,
    [apiAdsSlice.reducerPath]: apiAdsSlice.reducer,
    [apiCurrencySlice.reducerPath]: apiCurrencySlice.reducer,
    [apiPagesSlice.reducerPath]: apiPagesSlice.reducer,
    [apiProfileSlice.reducerPath]: apiProfileSlice.reducer,
    [apiTeachersSlice.reducerPath]: apiTeachersSlice.reducer,
    [apiCoursesSlice.reducerPath]: apiCoursesSlice.reducer,
    [apiConsultationsSlice.reducerPath]: apiConsultationsSlice.reducer,
    [apiServicesSlice.reducerPath]: apiServicesSlice.reducer,
    [apiDropDown.reducerPath]: apiDropDown.reducer,
    // local
    auth: authReducer,
    // checkList: checkListReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiAuthSlice.middleware,
      apiNoTokenAuthSlice.middleware,
      apiHomeSlice.middleware,
      apiWebsiteSlice.middleware,
      apiBlogSlice.middleware,
      apiUniversitySlice.middleware,
      apiFacultySlice.middleware,
      apiConsultationSlice.middleware,
      apiFaculitiesListSlice.middleware,
      apiSpecializationSlice.middleware,
      apiSpecializationListSlice.middleware,
      apiArticleSlice.middleware,
      apiAdsSlice.middleware,
      apiCurrencySlice.middleware,
      apiConsultationsSlice.middleware,
      apiContactSlice.middleware,
      apiPagesSlice.middleware,
      apiProfileSlice.middleware,
      apiTeachersSlice.middleware,
      apiCoursesSlice.middleware,
      apiServicesSlice.middleware,
      apiDropDown.middleware
    ),
  devTools: process.env.NODE_ENV === "development",
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
