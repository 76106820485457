"use client";
import Image from "next/image";

import { usePathname } from "next/navigation";
import LangSwitcherClient from "@/components/shared/common/LangSwitcher/LangSwitcherClient";
import Global from "@/public/icons/Global.svg";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import { useState, useEffect, useRef } from "react";

const LanguageSwitcher = () => {
  const pathname = usePathname();
  const { locale } = useTranslate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  if (pathname?.includes("dashboard")) return <></>;

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleLanguageChange = (lang: "en" | "ar") => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative mx-auto flex items-center justify-center"
      ref={dropdownRef}
    >
      <button
        onClick={toggleDropdown}
        className=" w-[90%] flex items-center justify-center gap-2 rounded-[6px] py-3 px-8 bg-[#F1F1F2] focus:outline-none"
      >
        <Image
          width={24}
          height={24}
          src={Global}
          alt="تغيير اللغة"
          className="block w-fit"
        />
        <span>{locale === "ar" ? "العربية" : "English"}</span>
      </button>
      {isDropdownOpen && (
        <ul className="absolute z-10 right-0 mt-2 w-32 bg-white border border-gray-200 rounded shadow-xl">
          <LangSwitcherClient
            className="w-fit"
            newLang="en"
            children={
              <li
                onClick={() => handleLanguageChange("en")}
                className={`text-center p-2 cursor-pointer hover:bg-gray-100 ${
                  locale === "en" ? "font-bold text-primaryColor" : ""
                }`}
              >
                English
              </li>
            }
          />
          <LangSwitcherClient
            className="w-fit"
            newLang="ar"
            children={
              <li
                onClick={() => handleLanguageChange("ar")}
                className={`text-center p-2 cursor-pointer hover:bg-gray-100 ${
                  locale === "ar" ? "font-bold text-primaryColor" : ""
                }`}
              >
                العربية
              </li>
            }
          />
        </ul>
      )}
    </div>
  );
};

export default LanguageSwitcher;
