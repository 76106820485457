import { useEffect, useState } from "react";
import { ChevronDown } from "lucide-react";
import Image from "next/image";
import { useGetSpecificProfileQuery } from "@/redux/features/api/profile/profileApiSlice";
import { useTranslate } from "@/components/base/providers/TranslateProv";

const UserProfileCard = () => {
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  // Fetch profile data using the query hook
  const { data, isLoading, error } = useGetSpecificProfileQuery({});

  useEffect(() => {
    if (data) {
      setFirstName(data.data?.first_name);
      setLastName(data.data?.last_name);
      setImageUrl(data.data?.image);
    }
  }, [data]);

  const Name = firstName && lastName ? `${firstName} ${lastName}` : "";
  const imageSrc = imageUrl
    ? imageUrl
    : `https://ui-avatars.com/api/?name=${firstName}+${lastName}`;

  const { locale } = useTranslate();
  return (
    <div
      style={{ cursor: "pointer" }}
      className={`flex items-center gap-3 overflow-hidden p-2 rounded-lg border bg-white w-[200px] mb-5 lg:mb-0 ${
        locale === "ar" ? "text-right" : "text-left"
      }`}
      dir={locale === "ar" ? "rtl" : "ltr"} // Apply the correct text direction
    >
      {/* Profile Picture */}
      <div className="min-h-10 max-h-10 min-w-10 max-w-10 rounded-full overflow-hidden">
        <Image
          src={imageSrc}
          alt={locale === "ar" ? "الصورة الشخصية" : "Profile Picture"}
          width={40}
          height={40}
          className="w-full h-full object-cover"
        />
      </div>

      {/* Text Section */}
      <div
        className={`w-[170px] overflow-hidden ${
          locale === "ar" ? "text-right" : "text-left"
        }`}
      >
        <h5 className="text-sm font-semibold text-[#141522]">
          {locale === "ar" ? "مرحبا" : "Hello"}
        </h5>
        <h6 className="text-gray-500 max-w-[170px] line-clamp-1">{Name}</h6>
      </div>
      <ChevronDown className="text-gray-500" />
    </div>
  );
};

export default UserProfileCard;
